* {
  font-family: 'JetBrains Mono', monospace;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root {
  background: #252641;
  display: flex;
  width: 100vw;
  height: 100vh;
}